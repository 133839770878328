var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":"修改基础信息","visible":_vm.visible,"width":"600px","before-close":_vm.handleClose,"close-on-click-modal":false,"element-loading-background":_vm.loadingBackground,"custom-class":"edit-customer-dialog"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{ref:"baseInfoForm",attrs:{"label-width":"120px","rules":_vm.rules,"model":_vm.baseInfoForm}},[_c('el-form-item',{attrs:{"label":"第一联系人","prop":"corpContactName"}},[_c('el-input',{attrs:{"placeholder":"第一联系人"},model:{value:(_vm.baseInfoForm.corpContactName),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "corpContactName", $$v)},expression:"baseInfoForm.corpContactName"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"第一联系电话","prop":"corpContactPhone"}},[_c('el-input',{attrs:{"placeholder":"第一联系电话"},model:{value:(_vm.baseInfoForm.corpContactPhone),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "corpContactPhone", $$v)},expression:"baseInfoForm.corpContactPhone"}})],1),_vm._v(" "),(_vm.taskSource != 3 && _vm.taskSource != 5)?_c('el-form-item',{attrs:{"label":"第二联系人","prop":"linkmanrpid"}},[_c('div',{staticClass:"select-content"},[_c('e6-vr-select',{attrs:{"data":_vm.contactList,"placeholder":"第二联系人","title":"第二联系人","props":{
            id: 'contactId',
            label: 'contactName',
          },"clearable":""},on:{"change":_vm.handleContact,"clear":_vm.handleClear},model:{value:(_vm.baseInfoForm.linkmanrpid),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "linkmanrpid", $$v)},expression:"baseInfoForm.linkmanrpid"}}),_vm._v(" "),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"text"},on:{"click":_vm.addConcat}},[_vm._v("\n          添加实施联系人\n        ")])],1)]):_c('el-form-item',{attrs:{"label":"第二联系人","prop":"contactName","rules":{
        required: true,
        message: '请输入第二联系人姓名',
        trigger: ['blur', 'change'],
      }}},[_c('div',{staticClass:"select-content"},[_c('el-input',{attrs:{"placeholder":"第二联系人"},model:{value:(_vm.baseInfoForm.contactName),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "contactName", $$v)},expression:"baseInfoForm.contactName"}})],1)]),_vm._v(" "),_c('el-form-item',{attrs:{"label":"第二联系电话","prop":"contactPhone"}},[_c('el-input',{attrs:{"placeholder":"第二联系电话"},model:{value:(_vm.baseInfoForm.contactPhone),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "contactPhone", $$v)},expression:"baseInfoForm.contactPhone"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"省市区地址","prop":"defaultCity"}},[_c('e6-vr-select',{staticStyle:{"width":"300px"},attrs:{"data":_vm.addressList,"placeholder":"联系地址","title":"联系地址","virtual":"","clearable":"","is-title":true,"remote":"","props":{
          id: 'fullName',
          label: 'fullName',
        }},on:{"filterChange":_vm.handleLoadAddressFilter,"change":_vm.handleCitySelect},model:{value:(_vm.baseInfoForm.defaultCity),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "defaultCity", $$v)},expression:"baseInfoForm.defaultCity"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"prop":"insAddress","label":"详细地址"}},[_c('el-input',{attrs:{"placeholder":"详细地址"},model:{value:(_vm.baseInfoForm.insAddress),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "insAddress", $$v)},expression:"baseInfoForm.insAddress"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"期望上门时间","prop":"expectTime"}},[_c('el-date-picker',{attrs:{"type":"datetime","placeholder":"选择日期","value-format":"timestamp","popper-class":"special","picker-options":_vm.pickerOptions1},model:{value:(_vm.baseInfoForm.expectTime),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "expectTime", $$v)},expression:"baseInfoForm.expectTime"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"实施原因","prop":"remark"}},[_c('el-input',{attrs:{"placeholder":"请输入实施原因","type":"textarea","rows":3},model:{value:(_vm.baseInfoForm.remark),callback:function ($$v) {_vm.$set(_vm.baseInfoForm, "remark", $$v)},expression:"baseInfoForm.remark"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.handleClose}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("确定")])],1),_vm._v(" "),_c('add-concat-dialog',{attrs:{"addContactVisible":_vm.addContactVisible,"isNeedReq":true,"corpId":_vm.baseInfoForm.corpId,"corpName":_vm.baseInfoForm.corpName},on:{"handleClose":_vm.handleCloseAddconcat,"updateList":_vm.updateList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }